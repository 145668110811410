@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,700,300italic,400italic,700italic%22");
@import url("https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.css");
@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");
body,
html {
  width: 100%;
  height: 100%; }

body {
  font-family: "Source Sans Pro"; }

.btn-xl {
  padding: 1.25rem 2.5rem; }

.content-section {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.content-section-heading h2 {
  font-size: 3rem; }

.content-section-heading h3 {
  font-size: 1rem;
  text-transform: uppercase; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700; }

.text-faded {
  color: rgba(255, 255, 255, 0.7); }

/* Map */
.map {
  height: 30rem; }

@media (max-width: 992px) {
  .map {
    height: 75%; } }

.map iframe {
  pointer-events: none; }

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 15px;
  opacity: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  line-height: 45px; }
  .scroll-to-top:focus, .scroll-to-top:hover {
    color: white; }
  .scroll-to-top:hover {
    background: #343a40; }
  .scroll-to-top i {
    font-weight: 800; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn; }

.fas {
  line-height: inherit; }

.masthead {
  min-height: 30rem;
  position: relative;
  display: table;
  width: 100%;
  height: auto;
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url("./img/bg-masthead.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .masthead h1 {
    font-size: 4rem;
    margin: 0;
    padding: 0; }
  @media (min-width: 992px) {
    .masthead {
      height: 100vh; }
      .masthead h1 {
        font-size: 5.5rem; } }

/* Side Menu */
#sidebar-wrapper {
  position: fixed;
  z-index: 2;
  right: 0;
  width: 250px;
  height: 100%;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  transform: translateX(250px);
  background-color: rgba(0,0,0,.7);
  border-left: 1px solid rgba(255, 255, 255, 0.1); }

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none; }

.sidebar-nav li.sidebar-nav-item a {
  display: block;
  text-decoration: none;
  color: #fff;
  padding: 15px; }

.sidebar-nav li a:hover {
  text-decoration: none;
  color: #fff;
  background: rgba(255, 255, 255, 0.2); }

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none; }

.sidebar-nav > .sidebar-brand {
  font-size: 1.2rem;
  background: rgba(52, 58, 64, 0.1);
  height: 80px;
  line-height: 50px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px; }

.sidebar-nav > .sidebar-brand a {
  color: #fff; }

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none; }

#sidebar-wrapper.active {
  right: 250px;
  width: 250px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s; }

.menu-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  width: 50px;
  height: 50px;
  text-align: center;
  color: #fff !important;
  background: rgba(52, 58, 64, 0.5);
  line-height: 50px;
  z-index: 999; }
  .menu-toggle:focus, .menu-toggle:hover {
    color: #fff; }
  .menu-toggle:hover {
    background: #343a40; }

.service-icon {
  background-color: #fff;
  color: #1d809f;
  height: 7rem;
  width: 7rem;
  display: block;
  line-height: 7.5rem;
  font-size: 2.25rem;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }

.icon-pencil,
.icon-screen-smartphone,
.icon-mustache,
.icon-like {
  line-height: inherit; }

/*.callout {
  padding: 15rem 0;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 100%), url("./img/bg-callout.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }
  .callout h2 {
    font-size: 3.5rem;
    font-weight: 700;
    display: block;
    max-width: 30rem; }*/

.portfolio-item {
  display: block;
  position: relative;
  overflow: hidden;
  max-width: 530px;
  margin: auto auto 1rem; }
  .portfolio-item .caption {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(33, 37, 41, 0.2);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1; }
    .portfolio-item .caption .caption-content {
      color: #fff;
      margin: auto 2rem 2rem; }
      .portfolio-item .caption .caption-content h2 {
        font-size: 0.8rem;
        text-transform: uppercase; }
      .portfolio-item .caption .caption-content p {
        font-weight: 300;
        font-size: 1.2rem; }
  @media (min-width: 992px) {
    .portfolio-item {
      max-width: none;
      margin: 0; }
      .portfolio-item .caption {
        -webkit-transition: -webkit-clip-path 0.25s ease-out, background-color 0.7s;
        -webkit-clip-path: inset(0px);
        clip-path: inset(0px); }
        .portfolio-item .caption .caption-content {
          transition: opacity 0.25s;
          margin-left: 5rem;
          margin-right: 5rem;
          margin-bottom: 5rem; }
      .portfolio-item img {
        -webkit-transition: -webkit-clip-path 0.25s ease-out;
        -webkit-clip-path: inset(-1px);
        clip-path: inset(-1px); }
      .portfolio-item:hover img {
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); }
      .portfolio-item:hover .caption {
        background-color: rgba(29, 128, 159, 0.9);
        -webkit-clip-path: inset(2rem);
        clip-path: inset(2rem); } }

footer.footer {
  padding-top: 5rem;
  padding-bottom: 5rem; }
  footer.footer .social-link {
    display: block;
    height: 4rem;
    width: 4rem;
    line-height: 4.3rem !important;
    font-size: 1.5rem;
    background-color: #1d809f;
    transition: background-color 0.15s ease-in-out;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1); }
    footer.footer .social-link:hover {
      background-color: #155d74;
      text-decoration: none; }

.icon-social-facebook,
.icon-social-twitter,
.icon-social-github {
  line-height: inherit; }

a {
  color: #1d809f; }
  a:hover, a:focus, a:active {
    color: #155d74; }

.btn-primary {
  background-color: #1d809f !important;
  border-color: #1d809f !important;
  color: #fff !important; }
  .btn-primary:hover, .btn-primary:focus, .btn-primary:active {
    background-color: #155d74 !important;
    border-color: #155d74 !important; }

.btn-secondary {
  background-color: #ecb807 !important;
  border-color: #ecb807 !important;
  color: #fff !important; }
  .btn-secondary:hover, .btn-secondary:focus, .btn-secondary:active {
    background-color: #ba9106 !important;
    border-color: #ba9106 !important; }

.btn-dark {
  color: #fff !important; }

.btn {
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  font-weight: 700; }

.bg-primary {
  background-color: #1d809f !important; }

.text-primary {
  color: #1d809f !important; }

.text-secondary {
  color: #ecb807 !important; }
