.App {
  text-align: center;
  font-family: sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  font-size: 100%;
}

.card-img-top {
  width: 100%;
  height: 25vw;
  object-fit: cover;
}

@media (max-width: 480px) {
  .card-img-top {
    width: 100%;
    height: 60vw;
    object-fit: cover;
  }
}

.projects {
  padding: 0 3%;
}

.projects .card:hover {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
}

.projects .card:hover .overlay {
  opacity: 0.9;
}

.projects .card:active .overlay {
  opacity: 0.9;
}

.projects .card {
  width: 85%;
}

.projects .card .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #1e1e2f;
}

.projects .card .overlay .text {
  color: white;
  font-size: 20px;
  position: relative;
  margin-right: 4%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.bg-foto-yo {
  background: url(scss/img/gato-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.colorlib-experience,
.colorlib-skills,
.colorlib-education,
.colorlib-blog,
.colorlib-work,
.colorlib-about,
.colorlib-services,
.colorlib-contact {
  padding-top: 2em;
  padding-bottom: 2em;
  clear: both;
  width: 100%;
  display: block;
}
@media screen and (max-width: 768px) {
  .colorlib-experience,
  .colorlib-skills,
  .colorlib-education,
  .colorlib-blog,
  .colorlib-work,
  .colorlib-about,
  .colorlib-services,
  .colorlib-contact {
    padding-top: 5em;
    padding-bottom: 8em;
  }
}

.colorlib-narrow-content {
  padding: 0 2.5em;
}
@media screen and (max-width: 768px) {
  .colorlib-narrow-content {
    padding: 0;
  }
}

.services {
  margin-bottom: 30px;
  padding: 1.5em;
  -webkit-box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  -moz-box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  -ms-box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  -o-box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
  border-bottom: 2px solid #2c98f0;
}
.services .icon2 {
  display: block;
  margin-bottom: 20px;
}
.services .icon2 i {
  font-size: 30px;
  color: #2c98f0;
}
.services h3 {
  font-family: "Quicksand", Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.services.color-2 {
  border-bottom: 2px solid #ec5453;
}
.services.color-2 .icon2 i {
  color: #ec5453;
}
.services.color-3 {
  border-bottom: 2px solid #f9bf3f;
}
.services.color-3 .icon2 i {
  color: #f9bf3f;
}
.services.color-4 {
  border-bottom: 2px solid #a84cb8;
}
.services.color-4 .icon2 i {
  color: #a84cb8;
}
.services.color-5 {
  border-bottom: 2px solid #2fa499;
}
.services.color-6 {
  border-bottom: 2px solid #4054b2;
}

.services {
  margin-bottom: 80px;
  position: relative;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 4em;
  }
}
.services .icon {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100px;
  height: 50px;
  display: table;
  margin: 0 auto;
  background: #2c98f0;
}
.services .icon:before,
.services .icon:after {
  position: absolute;
  left: 0;
  right: 0;
  content: "";
}
.services .icon:before {
  top: -30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 50px 30px 50px;
  border-color: transparent transparent #2c98f0 transparent;
}
.services .icon:after {
  bottom: -30px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 30px 50px 0 50px;
  border-color: #2c98f0 transparent transparent transparent;
}
.services .icon i {
  font-size: 30px;
  color: #fff;
  z-index: 1;
  display: table-cell;
  vertical-align: middle;
}
.services .desc {
  margin-top: 60px;
}
.services .desc h3 {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.services.color-2 .icon {
  background: #ec5453;
}
.services.color-2 .icon:before {
  border-color: transparent transparent #ec5453 transparent;
}
.services.color-2 .icon:after {
  border-color: #ec5453 transparent transparent transparent;
}
.services.color-3 .icon {
  background: #f9bf3f;
}
.services.color-3 .icon:before {
  border-color: transparent transparent #f9bf3f transparent;
}
.services.color-3 .icon:after {
  border-color: #f9bf3f transparent transparent transparent;
}
.services.color-4 .icon {
  background: #a84cb8;
}
.services.color-4 .icon:before {
  border-color: transparent transparent #a84cb8 transparent;
}
.services.color-4 .icon:after {
  border-color: #a84cb8 transparent transparent transparent;
}
.services.color-5 .icon {
  background: #2fa499;
}
.services.color-5 .icon:before {
  border-color: transparent transparent #2fa499 transparent;
}
.services.color-5 .icon:after {
  border-color: #2fa499 transparent transparent transparent;
}
.services.color-6 .icon {
  background: #4054b2;
}
.services.color-6 .icon:before {
  border-color: transparent transparent #4054b2 transparent;
}
.services.color-6 .icon:after {
  border-color: #4054b2 transparent transparent transparent;
}
.timeline-centered {
  position: relative;
  margin-bottom: 30px;
}

.timeline-centered:before,
.timeline-centered:after {
  content: " ";
  display: table;
}

.timeline-centered:after {
  clear: both;
}

.timeline-centered:before,
.timeline-centered:after {
  content: " ";
  display: table;
}

.timeline-centered:after {
  clear: both;
}

.timeline-centered:before {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  background: #f2f3f7;
  top: 20px;
  bottom: 20px;
  margin-left: 29px;
}

.timeline-centered .timeline-entry {
  position: relative;
  margin-top: 5px;
  margin-left: 30px;
  margin-bottom: 10px;
  clear: both;
}

.timeline-centered .timeline-entry .timeline-entry-inner {
  position: relative;
  margin-left: -20px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon {
  display: block;
  width: 40px;
  height: 40px;
  background: #2c98f0;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  text-align: center;
  -moz-box-shadow: 0 0 0 5px #f2f3f7;
  -webkit-box-shadow: 0 0 0 5px #f2f3f7;
  box-shadow: 0 0 0 5px #f2f3f7;
  line-height: 40px;
  float: left;
}
.timeline-centered .timeline-entry .timeline-entry-inner .timeline-icon i {
  color: #fff;
}
.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-icon.color-2 {
  background: #ec5453;
}
.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-icon.color-3 {
  background: #f9bf3f;
}
.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-icon.color-4 {
  background: #a84cb8;
}
.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-icon.color-5 {
  background: #2fa499;
}
.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-icon.color-none {
  background: #fff;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label {
  position: relative;
  background: #f2f3f7;
  padding: 1.5em;
  margin-left: 60px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 9px 9px 9px 0;
  border-color: transparent #f2f3f7 transparent transparent;
  left: 0;
  top: 10px;
  margin-left: -9px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label p + p {
  margin-top: 15px;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 {
  font-size: 20px;
  font-family: "Quicksand", Arial, sans-serif;
  font-weight: 500;
}

.timeline-centered .timeline-entry .timeline-entry-inner .timeline-label h2 a {
  color: #000;
}

.timeline-centered
  .timeline-entry
  .timeline-entry-inner
  .timeline-label
  h2
  span {
  -webkit-opacity: 0.4;
  -moz-opacity: 0.4;
  opacity: 0.4;
  -ms-filter: alpha(opacity=40);
  filter: alpha(opacity=40);
  font-size: 16px;
}

.language-selector {
  position: relative;

}

#languages-list {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
  list-style-type: none;
  display:none;
  text-align: center;
}

.language-selector:hover #languages-list {
  display:block;
}